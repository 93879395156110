import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Page, PageParam } from 'src/app/base/interfaces/page';
import { PageActionResponse } from 'src/app/base/interfaces/pageActionResponse';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute  } from '@angular/router';
import { AppidService } from '../appid/appid.service';

@Injectable({
    providedIn: 'root'
})

export class ContentService {

    public appID: number;
    constructor(private http: HttpClient, private auth: AuthService, private router: Router, private route: ActivatedRoute, private appid: AppidService) {
        this.appID = this.appid.appID;
    }

    getPage(navID: number, pageID: number = 0, pageParams?: PageParam[], auth: boolean = true, NavSourceV1?: string, V1SID?: string, V1OrgID?: string): Observable<Page | PageActionResponse> {
        let httpParams = new HttpParams().set('app_id', this.appID).set('nav_id', navID);
        if (pageID != 0) httpParams = httpParams.set('page_id', pageID);
        if (auth) httpParams = httpParams.set('org_id', this.auth.getOrg());
        if (NavSourceV1) httpParams = httpParams.set('NavSourceV1', NavSourceV1).set('sid', V1SID).set('org_id', V1OrgID);

        if (pageParams != null) {
            pageParams.forEach(pageParam => {
                httpParams = httpParams.set(pageParam.Name, pageParam.CurrentValue);
            });
        }

        // get any onLoad data that needs to be shown from back-end (ie. page description, form fields, etc.)
        return this.http.get<Page>(environment.urls.core + '/pages/get', {
            params: httpParams,
            headers: {
                'authorization': auth ? this.auth.getToken() : 'unknown person'
            }
        });
    }

    submitPage(navID: number, pageID: number = 0, fields: Record<number, string>, pageParams?: PageParam[], auth: boolean = true): Observable<PageActionResponse> {
        let httpParams = new HttpParams().set('app_id', this.appid.appID).set('nav_id', navID);
        if (pageID != 0) httpParams = httpParams.set('page_id', pageID);
        if (auth) httpParams = httpParams.set('org_id', this.auth.getOrg())

        if (pageParams != null) {
            pageParams.forEach(pageParam => {
                httpParams = httpParams.set(pageParam.Name, pageParam.CurrentValue);
            });
        }

        return this.http.post<any>(environment.urls.core + '/pages/submit', fields, {
            params: httpParams,
            headers: {
                'authorization': auth ? this.auth.getToken() : 'unknown person'
            }
        });
    }

    submitButtonClick(navID: number, pageID: number = 0, buttonName: string, pageParams?: PageParam[]): Observable<PageActionResponse> {
        let httpParams = new HttpParams().set('app_id', this.appid.appID).set('nav_id', navID);
        if (pageID != 0) httpParams = httpParams.set('page_id', pageID);

        if (this.auth.getOrg() != null) {
            httpParams = httpParams.set('org_id', this.auth.getOrg());
        }

        if (pageParams != null) {
            pageParams.forEach(pageParam => {
                httpParams = httpParams.set(pageParam.Name, pageParam.CurrentValue);
            });
        }
        
        return this.http.get<any>(environment.urls.core + `/pages/button`, {
            params: httpParams.set('buttonName', buttonName),
            headers: {
                'authorization': this.auth.getToken() == "" || this.auth.getToken() == null ? "unknown person" : this.auth.getToken()
            }
        });
    }

    submitTableLink(tableID: number, linkName: string, additionalParameters?: Record<string, any>): Observable<any> {
        let httpParams = new HttpParams()
            .set('app_id', this.appid.appID)
            .set('org_id', this.auth.getOrg())
            .set('tableID', tableID)
            .set('buttonName', linkName);

        return this.http.post<any>(environment.urls.core + `/tables/button`, additionalParameters, {
            params: httpParams,
            headers: {
                'authorization': this.auth.getToken()
            }
        });
    };

    submitAreYouSure(elementID: number, type: string, callback: string, additionalParameters?: Record<string, any>, V1_Parameters?: Record<string, any>): Observable<any> {
        let inProgress = false;

        let httpParams = new HttpParams()
            .set('app_id', this.appid.appID)
            .set('elementID', elementID)
            .set('callback', callback);

        if (V1_Parameters) {
            for (let key in V1_Parameters) {
                httpParams = httpParams.set(key, V1_Parameters[key]);
            }
        }

        if (this.auth.getToken() != null) {
            httpParams = httpParams.set('org_id', this.auth.getOrg())
            return this.http.post<any>(environment.urls.core + `/${type}/confirm`, additionalParameters, {
                params: httpParams,
                headers: {
                    'authorization': this.auth.getToken()
                }
            });
        } else {
            return this.http.post<any>(environment.urls.core + `/${type}/confirm`, additionalParameters, {
                params: httpParams
            });
        }
    }

    getTableData(tableID: number, filter = '', sortOrder = 'asc', page = 1, pageSize = 25, pageID, additionalParameters?: Record<string, any>): Observable<any> {
        let httpParams = new HttpParams()
            .set('app_id', this.appid.appID)
            .set('page_id', pageID)
            .set('org_id', this.auth.getOrg())
            .set('tableID', tableID)
            .set('filter', filter)
            .set('sortOrder', sortOrder)
            .set('page', page)
            .set('pageSize', pageSize);

        if (additionalParameters != null) {
            for (let key in additionalParameters) {
                httpParams = httpParams.set(key, additionalParameters[key]);
            }
        }

        return this.http.get<any>(environment.urls.core + `/tables/get`, {
            params: httpParams,
            headers: {
                'authorization': this.auth.getToken()
            }
        });
    }

    upsertTablePersonViews(tableID, columnSort, orderSort, pageSize, pageID) {
        let httpParams = new HttpParams()
            .set('app_id', this.appid.appID)
            .set('org_id', this.auth.getOrg())
            .set('page_id', pageID)
            .set('tableID', tableID)
            .set('columnSort', columnSort)
            .set('orderSort', orderSort)
            .set('pageSize', pageSize);

        return this.http.post<any>(environment.urls.core + `/tables/upsertTablePersonViews`, {}, {
            params: httpParams,
            headers: {
                'authorization': this.auth.getToken()
            }
        })
    }

    uploadFile(fieldID: number | string, file: File): Observable<any> {
        let httpParams = new HttpParams()
            .set('app_id', this.appid.appID)
            .set('org_id', this.auth.getOrg())
            .set('field_id', fieldID)

        return this.http.post<any>(environment.urls.core + `/files/upload`, file, {
            params: httpParams,
            headers: {
                'authorization': this.auth.getToken()
            }
        });
    }

    exportTable(tableID: number, exportType: string): Observable<any> {
        let httpParams = new HttpParams()
            .set('app_id', this.appid.appID)
            .set('org_id', this.auth.getOrg())
            .set('tableID', tableID)
            .set('exportType', exportType);

        return this.http.get<any>(environment.urls.core + `/tables/export`, {
            params: httpParams,
            headers: {
                'authorization': this.auth.getToken()
            },
            responseType: exportType == 'text' ? 'text' as 'json' : 'blob' as 'json'
        });
    }

    /**
     * When a registration workflow is in effect, calling this method with a given step ID
     * will mark that step as complete. A PageAction response will be returned, likely containing
     * a redirect to the next step in the process.
     * @param stepID The ID of the step to be marked as completed
     */
    completeWorkflowStep(navID: number, pageID: number, stepID: number | string): Observable<PageActionResponse> {
        let httpParams = new HttpParams()
            .set('app_id', this.appid.appID)
            .set('org_id', this.auth.getOrg())
            .set('page_id', pageID)
            .set('nav_id', navID)
            .set('org_id', this.auth.getOrg())
            .set('stepID', stepID);

        return this.http.post<PageActionResponse>(environment.urls.core + `/pages/workflowcomplete`, {}, {
            params: httpParams,
            headers: {
                'authorization': this.auth.getToken()
            }
        });
    }
}
