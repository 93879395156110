import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';
import { TextfieldService } from 'src/app/base/services/textfield/textfield.service';
import { originalOrder } from 'src/app/gigmonster/components/form/form.component'

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup;

    constructor(private TextfieldService: TextfieldService) { }

    // Directly assign the imported originalOrder function to this classes property so that the html file can use it.
    originalOrder = originalOrder;

    getIn() {
        return this.field.instructions;
    }

    onSelectionChange(event, choices) {
        //slot dropdown
        if(event.source.ngControl.name == '734'){
            this.TextfieldService.setSharedData({fieldId: '734', price: choices[event.value].price});
        }
    }
    
    ngAfterViewInit() {
        //if the dropdown has only one value, set it to that value.
        if (Object.keys(this.field.choices).length === 1) {
            const firstOptionValue = Object.keys(this.field.choices)[0];
            this.formGroup.get(this.fieldKey).setValue(firstOptionValue);
        }
    }
}
