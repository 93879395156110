<ng-container>
    <ng-container *ngIf="table.buttons">
        <div *ngIf="generateButtons(table.buttons)" class="tableButtons">
          <ng-container *ngFor="let button of table.buttons | keyvalue">
            <button
              mat-raised-button
              color="primary"
              *ngIf="button.key !== ''"
              (click)="handleButtonClick(button.value)">{{button.value.Label}}
            </button>
          </ng-container>
        </div>
      </ng-container>
    <!-- <div class="tableButtons">
        <button mat-raised-button color="primary" *ngFor="let button of table.buttons | keyvalue" (click)="handleButtonClick(button.value)">{{button.value.Label}}</button>
    </div> -->

    <div class="searchAndExport">
        <div class="tableCentering">
            <span *ngIf="tableSortOrderText" class="tableSortOrderText">
                Sorted By: {{ tableOrder.columnName }}.
            </span>
            <div *ngIf="this.dataSource.dataLength >= 25">
                <p (click)="toggleSearch()" style="cursor: pointer;">{{this.showSearch ? "Hide Search" : "Show Search"}}</p>
                <div *ngIf="this.showSearch" class="searchFields">
                    <ng-container *ngFor="let col of this.table.columns; let i=index;">
                        <mat-form-field appearance="outline" *ngIf="col.searchable">
                            <mat-label>{{col.header}}</mat-label>
                            <input (keyup)="filter($event, i)" matInput placeholder="{{col.header}}">
                        </mat-form-field>
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- <div class="exportImages">
            <img src='../../../assets/pdf.png' alt='pdf' (click)="exportPDF()">
            <img src='../../../assets/txt.png' alt='txt' (click)="exportTxt()">
            <img src='../../../assets/xls.png' alt='xls' (click)="exportExcel()">
        </div> -->
    </div>

    <div class="table-container">
        <table matSort [matSortActive]="activeColumnIndex" [matSortDirection]="sortDirection" (matSortChange)="sortData($event)" mat-table [dataSource]="dataSource">
            <ng-container *ngFor="let column of table.columns; let i = index" [matColumnDef]="column.header">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="i.toString()" [disabled]="column.sortable == '0'">
                    {{column.header}} 
                    <mat-icon class="table-helper-icon" *ngIf="column.headerTooltip" matTooltip="{{column.headerTooltip}}">help_outline</mat-icon>
                </th>
                <td mat-cell *matCellDef="let row" [class.isExpand]='column.collapsible && isExpand' (click)="isExpandToggle(column)">
                    <ng-container *ngIf="!hasLink(row[i]) && !hasCheckbox(row[i])">
                        {{row[i]}}
                    </ng-container>
                    <ng-container *ngIf="row[i] === '[[edit]]'">
                        EDIT (needs callback!)
                    </ng-container>
                    <ng-container *ngIf="row[i] === '[[delete]]'">
                        DELETE (needs callback!)
                    </ng-container>
                    <ng-container *ngIf="hasCustomLink(row[i])">
                        <a (click)="tableLinkClick(buildLink(row[i]))">{{buildLink(row[i]).linkText}}</a>
                    </ng-container>
                    <ng-container *ngIf="hasCallbackLink(row[i])">
                        <a (click)="handleCallbackLink(buildCallbackLink(row[i]))">{{buildCallbackLink(row[i]).linkText}}</a>
                    </ng-container>
                    <ng-container *ngIf="hasCheckbox(row[i])">
                        <mat-checkbox (change)="checkboxChange(row[i])"></mat-checkbox>
                    </ng-container>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="columnStrings"></tr>
            <tr mat-row *matRowDef="let row; columns: columnStrings; "></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="table.columns.length">
                    <ng-container *ngIf="this.initialTableLoad">Loading...</ng-container>
                    <ng-container *ngIf="!this.initialTableLoad">No results found.</ng-container>
                </td>
            </tr>
        </table>
    </div>


    <mat-paginator [length]="this.table.totalRecords" [pageSize]="25 " [pageSizeOptions]="[5, 10, 25, 100] " aria-label="Select page of table "></mat-paginator>
</ng-container>