<ng-container [formGroup]="this.formGroup">
        <td valign="middle" class="label_field_153" style="font-weight: 500;">{{field.label}}<span
                style="color:red">*</span>:</td>
        <td valign="middle">
            <span *ngFor="let choice of field.choices | keyvalue: originalOrder">
                <label style="font-weight:normal" class="control control--radio aaaaaa ">
                    <input type="radio"  [formControlName]="this.fieldKey" value="{{choice.key}}" class="field_submit multi-radio">
                    <span *ngIf=" choice.value.value">{{choice.value.value}}</span>
                    <span *ngIf="!choice.value.value">{{choice.value}}</span>
                    <div class="control__indicator"></div>
                </label>
                <br>
            </span>
        </td>
</ng-container>