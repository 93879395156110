import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FieldBase } from 'src/app/base/components/fields/upload-base.component copy';
import { FormBaseComponent } from 'src/app/base/components/form-base.component';
import { FormComponent } from 'src/app/gigmonster/components/form/form.component';
import { ContentService } from 'src/app/base/services/content/content.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-easysuite-form',
    templateUrl: './easysuite-form.component.html',
    styleUrls: ['./easysuite-form.component.css'],
})
export class EasysuiteFormComponent extends FormComponent implements OnInit {

    constructor(protected route: ActivatedRoute, protected dialog: MatDialog, protected contentService: ContentService) { 
        super(route, dialog, contentService); 
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

}
