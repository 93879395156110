import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';
import { originalOrder } from 'src/app/gigmonster/components/form/form.component'

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css']
})
export class RadioComponent {
  @Input() field: Field;
  @Input() fieldKey: string;
  @Input() formGroup: UntypedFormGroup;

  constructor() {}

  // Directly assign the imported originalOrder function to this classes property so that the html file can use it.
  originalOrder = originalOrder;
}
