import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ContentService } from 'src/app/base/services/content/content.service';
import { Page, PageParam } from 'src/app/base/interfaces/page';

import { environment } from '../../../environments/environment'
import { PageActionResponse } from 'src/app/base/interfaces/pageActionResponse';
import { first, Subject, switchMap, of, Observable, map } from 'rxjs';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../../gigmonster/components/popup/popup.component';
import { AreyousureComponent } from '../../gigmonster/components/areyousure/areyousure.component';

import { AuthService } from 'src/app/base/services/auth/auth.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { I3VService } from 'src/app/base/services/i3v/i3v.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { WorkflowService } from 'src/app/base/services/workflow/workflow.service';
import { AppComponent } from 'src/app/app.component';
import { AppidService } from 'src/app/base/services/appid/appid.service';
import { ThemeService } from '../services/theme/EZScssTheme.service';
import { ButtonService } from '../services/buttons/buttons.service';


@Component({
    selector: 'app-superstructure-base',
    template: ``,
    styles: []
})
export class SuperstructureBaseComponent implements OnInit {
    page: Page;
    pageID: number;
    navID: number;

    providedPageID: number;
    providedNavID: number;

    errorText = "";

    production: Boolean = environment.production;
    pageParams: PageParam[];

    reloadTableSubject: Subject<any> = new Subject<void>();
    reloadTableWithDataSubject: Subject<any> = new Subject<void>();

    descriptionVisible: boolean = false;
    pageLoading: boolean = true;
    loadTopAnimationOnly: boolean = false;

    futureSubmitMessage = null;
    showWorkflowNextStepMessage = false;
    showWorkflowSkipMessage = false;

    registerMode = false;
    registerRole = "";
    forgotPassword = false;
    confirmPassword = false;

    areYouSureInProgress = false;
    submitInProgress = false;

    @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;

    constructor(
        public route: ActivatedRoute,
        public content: ContentService,
        public router: Router,
        public sanitizer: DomSanitizer,
        public dialog: MatDialog,
        public location: Location,
        public cdr: ChangeDetectorRef,
        public titleService: Title,
        public auth: AuthService,
        public nav: NavService,
        public i3v: I3VService,
        public workflow: WorkflowService,
        public app: AppComponent,
        public AppId: AppidService,
        public http: HttpClient,
        public theme: ThemeService,
        public buttonService:  ButtonService,   
    ) {}

    ngOnInit(): void {  

        // Call the asynchronous function to get the appID and when it returns it then call the loadTheme to get the correct css for the current app ID
        this.AppId.getAppID().then(appID => {
            this.theme.loadTheme(appID);
        });

        let authToken = localStorage.getItem('token');

        if (authToken == null) {
            this.registerMode = true;
        }

        this.route.queryParams.subscribe(urlParams => {
            this.pageLoading = true;

            //when the NavSourceV1 is defined, it means the request is coming from V1
            if(urlParams['NavSourceV1'] !== undefined){
                localStorage.setItem('NavSourcev1', 'yes');
                localStorage.setItem('V1SID', urlParams['sid']);
                localStorage.setItem('V1OrgID', urlParams['org_id']);
            }

            //"reset" local variables to avoid weirdness
            this.page = null;
            this.pageID = null;
            this.navID = null;
            this.pageParams = null;
            this.errorText = "";
            this.reloadTableSubject = new Subject<void>();
            this.descriptionVisible = false;
            this.showWorkflowNextStepMessage = false;
            this.showWorkflowSkipMessage = false;
            this.pageParams = this.buildParameters(urlParams);


            // if the routes object does not exist, then that means they are an unauth person, so get unauth navs
            if (localStorage.getItem("routes") == null) {
                //unauthenticated navigation will be loaded.
                // Clear the existing navigation items for unauthenticated users.
                this.nav.clearNavs().subscribe(
                    (data) => {},
                );
            }

            this.pageID = this.providedPageID != null ? this.providedPageID : this.pageID == null ? urlParams["page"] : this.pageID;
            this.navID = this.providedNavID != null ? this.providedNavID : this.route.snapshot.data.nav;

            // front-end double check that token is valid (will be done on back end too)
            this.content.getPage(this.navID, this.pageID, this.pageParams, localStorage.getItem('token') != null, localStorage.getItem('NavSourcev1'), localStorage.getItem('V1SID'), localStorage.getItem('V1OrgID')).subscribe(
                async (data: Page | PageActionResponse) => {

                    // no data from back-end so serious problems (usually for development)
                    if (data == null) {
                        console.error("Get page returned null response");
                        this.errorText = "Get page returned null response";
                        return;
                    }

                    if (this.instanceOfPageAction(data)) {
                        let pageAction: PageActionResponse = data;
                        this.handleActionResponse(pageAction);

                    } else {
                        
                        //data is the response we receive from the v2 backend. 
                        this.page = data;

                        // data and this.page refers to the common JS object.

                        this.titleService.setTitle(data.Title);
                        if (this.pageID == null) this.pageID = data.PageID;

                        if (this.futureSubmitMessage != null) this.page.SubmitMessage = this.futureSubmitMessage;

                        //update 11/16/22, the login component can now set the submit message via a url param,
                        //so we'll need to set that.
                        if (urlParams["msg"]) {
                            this.page.SubmitMessage = urlParams["msg"];
                        }
                        
                        //debug information in console.
                        console.warn("Page: " + this.page.PageID + ", Nav: " + this.navID);
                        if (this.workflow.isInWorkFlow()) {
                            //if we're in workflow mode, download the submit message for this page if applicable
                            //will also determine if we're supposed to show the "click next" text.
                            let workflowMessageData = await this.workflow.getMessage(this.page.PageID).toPromise();

                            if (workflowMessageData) {
                                if (workflowMessageData.submitMessageContent) this.page.SubmitMessage = workflowMessageData.submitMessageContent;
                                if (workflowMessageData.showContinueText) this.showWorkflowNextStepMessage = workflowMessageData.showContinueText;
                                if (workflowMessageData.showSkipText) this.showWorkflowSkipMessage = workflowMessageData.showSkipText;
                            }
                        }

                        this.pageLoading = false;
                        this.futureSubmitMessage = null;
                        this.triggerLoading(false);
                    }
                },
                (error: any) => {
                    if (error.status == 404) {
                        console.error("page " + this.pageID + " not present in RADMAP");
                        this.errorText = "No associated Page class found in CoreService: nav id " + this.navID;
                    } else if (error.status == 500) {
                        this.errorText = error.error;
                        this.pageLoading = false;
                        this.triggerLoading(false);
                    }
                },
            );
        });
    }

    instanceOfPageAction(object: any): object is PageActionResponse {
        return 'action' in object;
    }

    getDescription() {

        if (this.page.Description !== null) {
            const rawHtml = this.page.Description; // Assuming this.page.Description contains the HTML content

            // Sanitize HTML content to make it safe for rendering
            const sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(rawHtml);

            return sanitizedHtml;
        }

        return null;
    }

    getMoreDescription() {
        return this.sanitizer.bypassSecurityTrustHtml(this.page.MoreDescription);
    }

    toggleDescription(): void {
        this.descriptionVisible = !this.descriptionVisible;
    }

    triggerLoading(activate: boolean): void {
        this.loadTopAnimationOnly = activate;
        this.cdr.detectChanges();
        if (!activate) this.submitInProgress = false;
    }

    buildParameters(urlParams: Params): PageParam[] {
        let pageParams: PageParam[] = [];
        for (let paramKey in urlParams) {
            pageParams.push({
                Name: paramKey,
                Type: "string",
                CurrentValue: urlParams[paramKey]
            });
        }

        return pageParams;
    }

    handleButtonLink(type: string, label: string, link: string): void {
        if (type == "link") {
            //check if link is just applying a "page" parameter
            if (link.indexOf("page=") > -1) {
                this.router.navigateByUrl(this.router.url + link);
            }
        } else if (type == "callback") {
            this.content.submitButtonClick(this.navID, this.pageID, label, this.pageParams).subscribe({
                next: (data: PageActionResponse) => this.handleActionResponse(data),
                error: (error: any) => {
                    console.error(error);
                    this.errorText = error.error;
                },
            });
        }
    }

    formValidAndSubmitted(values: Record<number, string>): void {
        if (this.submitInProgress) return;
        this.submitInProgress = true;

        this.triggerLoading(true);

        //SPECIAL CASE: credit card tokenization must be handled client side.
        if (this.page.PageResources && this.page.PageResources["I3V_PUBLIC"]) {
            let i3vKey = this.page.PageResources["I3V_PUBLIC"];
            let i3vAccount = this.page.PageResources["I3V_ACCOUNT"];
            let i3vURL = this.page.PageResources["I3V_URL"];
            this.i3v.tokenizeCreditCard(i3vURL, i3vAccount, i3vKey, values[159], values[160], values[161], values[162]).subscribe({
                next: (data: any) => {
                    if (data.ResultCode == 0) {
                        this.pageParams.push({
                            Name: "token",
                            CurrentValue: data.Token
                        });

                        this.pageParams.push({
                            Name: "Last4",
                            CurrentValue: data.Last4
                        })

                        //remove 159, 160, 161, and 162 from values
                        for (let i = 159; i <= 162; i++) {
                            delete values[i];
                        }

                        this.content.submitPage(this.navID, this.pageID, values, this.pageParams, localStorage.getItem('token') != null).subscribe(
                            (data: PageActionResponse) => {
                                this.handleActionResponse(data);
                                this.triggerLoading(false);
                            },
                            (error: any) => {
                                console.error(error);
                            },
                        );
                    } else {
                        this.page.SubmitMessage = "Your card failed to verify. Please try again.";
                        this.triggerLoading(false);
                    }
                },
                error: (error: any) => {
                    console.error(error);
                }
            });
        }

        // This is not a credit card page
        else {
            //if registrationRole present, add it to pageparams
            if (this.registerRole != null) {
                this.pageParams.push({
                    Name: "role",
                    CurrentValue: this.registerRole
                });
            }

            this.content.submitPage(this.navID, this.pageID, values, this.pageParams, localStorage.getItem('token') != null).subscribe(
                (data: PageActionResponse) => {
                    this.handleActionResponse(data);
                    this.triggerLoading(false);
                },
                (error: any) => {
                    console.error(error);
                },
            );
        }
    }

    markWorkflowStep(): void {
        this.triggerLoading(true);
        this.workflow.completeStep(this.pageID).subscribe({
            next: (data: PageActionResponse) => {
                this.handleActionResponse(data);
                this.triggerLoading(false);
            },
            error: (error: any) => {
                console.error(error);
            }
        });
    }

    skipWorkflowStep(): void {
        this.triggerLoading(true);
        this.workflow.skipStep(this.pageID).subscribe({
            next: (data: PageActionResponse) => {
                this.handleActionResponse(data);
                this.triggerLoading(false);
            },
            error: (error: any) => {
                console.error(error);
            }
        });
    }

    triggerSupportLink(): void {
        this.handleActionResponse({
            action: "redirect",
            pageID: 330,
            options: []
        });
    }

    // Real 15 login is successful (Fake 15 is elsewhere)
    async login(action): Promise<void> {
        this.auth.setOrgAndRole(action.newOrg, action.newRole);
        this.auth.saveToken(action.token);

        let roleID = this.auth.getRole();
        let targetNav = action.next;
        //283 or 287 should also use workflowservice to get which page to go to
        this.http.get<boolean>(environment.urls.core + `/auth/isRegistrationRole?role_id=${roleID}`).pipe(
            switchMap(isRegistrationRole => {
                if (isRegistrationRole) {
                    return this.workflow.initialLogin();
                } else {
                    this.workflow.disableWorkflow();
                    return of(null); // Returns a dummy observable if not a registration role
                }
            })
        ).subscribe(workflowData => {
            if (workflowData) {
                targetNav = workflowData["page"];
            }

            // grab the navs for the role
            this.nav.updateNavs(this.auth.getToken(), this.auth.getRole()).subscribe(
                (routeLoaded) => {
                    let url = null;

                    // get the url to send the user based on the page returned by back-end
                    url = this.router.config.find(route => {
                        return route.data && route.data.navPage == targetNav;
                    });

                    if (url) url = url.path;

                    // no url if back-end returns a nav instead
                    if (!url) {
                        url = this.router.config.find(route => route.data?.nav === targetNav).path;
                    }

                    if (action.msg) this.futureSubmitMessage = action.msg;

                    this.router.navigateByUrl(url);
                },
                (error) => {
                    console.error(error);
                }
            );
        });
    }

    handleActionResponse(action: PageActionResponse): void {
        if (action == null) {
            console.error("action response is null");
            return;
        }

        switch (action.action) {
            case "reload_table":
                this.reloadTableSubject.next(action.options);
                this.page.SubmitMessage = action.message;
                break;
            case "reload_table_with_existing_data":
                // if the reload_table_with_existing_data reload response has a message, then assign it to the submit message
                this.page.SubmitMessage = action.message;
                this.reloadTableWithDataSubject.next(action.options);
                break;
            case "redirect_nav":
                let targetPageWithNav = this.router.config.find(route => {
                    return route.data &&
                        route.data.nav &&
                        route.data.nav == action.navID &&
                        route.data.hasPage &&
                        route.data.navPage == action.pageID;
                });
                if (targetPageWithNav) {

                    this.router.navigate([targetPageWithNav.path], {
                        queryParams: {
                            msg: action.message
                        }
                    });
                }
                else {
                    this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: this.route.snapshot.queryParams,
                        queryParamsHandling: "merge"
                    });
                }
                break;
            case "redirect_to_v1":
                //remove v1 related local storage before redirecting the user to v1.
                localStorage.removeItem('NavSourcev1');
                localStorage.removeItem('V1SID');
                localStorage.removeItem('V1OrgID');

                // Create a form element to submit so that the user can be redirected to v1
                // The reason we want to do this instead of just changing the window location is so that we can send through hidden variables as well 
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = action.v1Url;

                // TO DO: Hidden variable logic
                // I think the commented out code works but need to test it
                /*
                for (let key in action.options[HiddenVariables]) {
                    const hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = key;
                    hiddenField.value = action.options[HiddenVariables][key];
                    form.appendChild(hiddenField);
                }
                */

                // If there is a message (submit_message) then add it as a hidden variable 
                // because if it is long then we do not want to put it in the url
                if (action.message) {
                    const hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = 'submit_msg';
                    hiddenField.value = action.message;
                    form.appendChild(hiddenField);
                }
                
                document.body.appendChild(form);
                form.submit();
                break;
            case "redirect":
                // check to see if the shouldUpdateOrg option exists, and if it is, update the org cookie in localStorage
                if (action.options["shouldUpdateOrg"]) {
                    this.auth.setOrgAndRole(action.options["orgID"], parseInt(localStorage.getItem("role")));
                }

                //clear everything from params by creating a param dictionary with existing params and setting all to null
                this.route.queryParams.pipe(first()).subscribe(x => {
                    let params: Params = {};
                    for (let key in x) {
                        params[key] = null;
                    }

                    params["page"] = action.pageID;
                    for (let paramKey in action.options["additionalParams"]) {
                        params[paramKey] = action.options["additionalParams"][paramKey];
                    }
                    
                    //submit message from the v2 backend
                    if (action.message) params["msg"] = action.message;

                    //looks like temp variable.
                    this.futureSubmitMessage = action.message;

                    if (action.pageID == 9999) {
                        this.router.navigateByUrl("/auth/login?msg=" + action.message);
                        return;
                    }

                    if (this.scrollbarRef) {
                        this.scrollbarRef.scrollTo({
                            top: 0
                        });
                    } else {
                        console.warn("scrollbarRef not present");
                    }

                    //inspect the router config to determine if there is already a nav page with the target pageID
                    let targetNav = this.router.config.find(route => {
                        return route.data && route.data.hasPage && route.data.navPage == action.pageID;
                    });

                    if (targetNav) {
                        this.router.navigate([targetNav.path], {
                            queryParams: params
                        });
                    } else {
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: params,
                            queryParamsHandling: "merge"
                        });
                    }
                });
                break;

            case "message":
                this.page.SubmitMessage = action.message;
                if (this.scrollbarRef) {
                    this.scrollbarRef.scrollTo({
                        top: 0,
                        left: 0
                    });
                } else {
                    console.warn("scrollbarRef not present");
                }
                break;

            case "popup":
                const popupDialog = this.dialog.open(PopupComponent, {
                    data: {
                        message: action.options["message"],
                    }
                });
                break;

            case "areyousure":
                const areyousureDialog = this.dialog.open(AreyousureComponent, {
                    data: {
                        question: action.options["question"],
                        yesAnswer: action.options["yesAnswer"],
                        noAnswer: action.options["noAnswer"],
                    }
                });
                areyousureDialog.afterClosed().subscribe(x => {
                    if (x && !this.areYouSureInProgress) {
                        this.areYouSureInProgress = true;

                        this.content.submitAreYouSure(action.options["elementID"], action.options["type"], action.options["callback"], action.options["additionalParams"], action.options["V1_Params"]).subscribe({
                            next: (data: PageActionResponse) => {
                                this.areYouSureInProgress = false;
                                this.handleActionResponse(data);
                            },
                            error: (error: any) => {
                                this.areYouSureInProgress = false;
                                console.error(error);
                            }
                        });
                    }
                });
                break;

            case "go_back":
                this.location.back();
                break;
            case "submitExisting":
                //add actions.options to pageParams
                for (let paramKey in action.options) {
                    this.pageParams.push({
                        Name: paramKey,
                        Type: "string",
                        CurrentValue: action.options[paramKey]
                    });
                }

                this.content.submitPage(this.navID, this.pageID, {}, this.pageParams).subscribe(
                    (data: PageActionResponse) => {
                        if (this.reloadTableSubject != null) this.reloadTableSubject.next(action.options);
                        this.handleActionResponse(data);
                    }
                );
                break;

            case "ResubmitPage":

                for (let paramKey in action.options["Parameters"]) {
                    this.pageParams.push({
                        Name: paramKey,
                        CurrentValue: action.options["Parameters"][paramKey]
                    });
                }

                this.content.submitPage(this.navID, this.pageID, action.options["FormData"], this.pageParams, localStorage.getItem('token') != null).subscribe(
                    (data: PageActionResponse) => {
                        this.handleActionResponse(data);
                        this.triggerLoading(false);
                    },
                    (error: any) => {
                        console.error(error);
                    }
                );
                break;
            case "login":
                this.login(action.options);
                break;
            case "register":
                this.login(action.options);
                break;
            default:
                console.error("unknown action: " + action.action);
                console.warn(action);
        }

    }

}
